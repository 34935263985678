import App from './App';
import React from 'react';
import axios from "axios";
import { Provider } from 'react-redux';
import ReactDOM from 'react-dom/client';
import {store, persistor} from "./store/index";
import { PersistGate } from "redux-persist/integration/react";

// axios.defaults.baseURL = "http://localhost/test/curl.php?url=https://api.vaikerai.com/web/";
axios.defaults.baseURL = "https://api.vaikerai.com/web/";

axios.interceptors.response.use(function (response) {
  //console.log('interceptor', response.data?.message);
  if(response.data?.message === 'Session Expired, Please login again') {
    sessionStorage.removeItem('persist:root');
    localStorage.removeItem('persist:root');
    //console.log('response.data', response.data);
    document.location.href = '/login';
    return Promise.reject(null);
  }
  return response;
}, function (error) {
  return error;
});

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <Provider store={store}>
    <PersistGate persistor={persistor}>
      <App />
    </PersistGate>
  </Provider>
);
