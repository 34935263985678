import axios from "axios";
import { createSlice } from "@reduxjs/toolkit";
import { ToastError, ToastSuccess } from "../../views/common/Toast";
/***
 * Login Reducer initial state
 * ***/
const ImageGenState = { isLoading: false, isError: null, isSuccess: false, imageGenRes: null, isPSuccess: false, myImgPrompts: null, details: null };

const ImageGenSlice = createSlice({
    name: "ImageGen",
    initialState: ImageGenState,
    reducers: {
        apiCallRequested(state) {
            state.isLoading = true;
            state.isError = null;
            state.isSuccess = false;
        },
        apiFailed(state, action) {
            state.isLoading = false;
            state.isError = action.payload;
        },
        apiSuccess(state,action){
            state.isLoading = false;
            state.feedsRes = action.payload;
            state.isSuccess = true;
        },
        apiImgPromptsSuccess(state,action){
            state.isLoading = false;
            state.myImgPrompts = action.payload;
            state.isPSuccess = true;
        },
        apiDetailsSuccess(state,action){
            state.isLoading = false;
            state.details = action.payload;
            state.isPSuccess = true;
        },
        resetState(state){
            state.isError = null;
            state.isSuccess = false;
        }
    }
});

export const postImageGenApi = (data) => {
    return async (dispatch, getState) => {
        dispatch(ImageGenActions.apiCallRequested());
        const token = getState()?.LoginRes?.authResToken;
        let formData = {
            command: "generate-images",
            ...data
        };
        try {
            const res = await axios({
                method: "POST",
                data: formData,
                headers:{
                    Authorization:`Bearer ${token}`
                }
            });

            const { errors, message, data } = res?.data;
            if(errors === false){
                ToastSuccess(message)
                dispatch(ImageGenActions.apiSuccess(data))
                dispatch(getImgPromptsApi())
            }else{
                throw message;
            }
        } catch (error) {
            dispatch(ImageGenActions.apiFailed(error));
            if(typeof error === 'string')
                ToastError(error)
            else
                ToastError("Please validate errors.")
        }
    }
};

export const getImgPromptsApi = () => {
    return async (dispatch, getState) => {
        dispatch(ImageGenActions.apiCallRequested());
        const token = getState()?.LoginRes?.authResToken;
        
        let formData = {
            command: "my-img-prompts"
        };

        try {
            const res = await axios({
                method: "POST",
                data: formData,
                headers:{
                    Authorization:`Bearer ${token}`
                }
            });

            const { errors, message, data } = res?.data;
            if(!errors){
                dispatch(ImageGenActions.apiImgPromptsSuccess(data));
            }else{
                throw message;
            }
        } catch (error) {
            dispatch(ImageGenActions.apiFailed(error));
            console.log(error)
        }
    }
};

export const getDetailsApi = (data) => {
    return async (dispatch, getState) => {
        dispatch(ImageGenActions.apiCallRequested());
        const token = getState()?.LoginRes?.authResToken;
        
        let formData = {
            command: "get-details",
            ...data
        };

        try {
            const res = await axios({
                method: "POST",
                data: formData,
                headers:{
                    Authorization:`Bearer ${token}`
                }
            });

            const { errors, message, data } = res?.data;
            if(!errors){
                dispatch(ImageGenActions.apiDetailsSuccess(data));
            }else{
                throw message;
            }
        } catch (error) {
            dispatch(ImageGenActions.apiFailed(error));
            console.log(error)
        }
    }
};


export const ImageGenActions = ImageGenSlice.actions;

export default ImageGenSlice.reducer;
