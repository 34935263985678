import axios from "axios";
import { createSlice } from "@reduxjs/toolkit";
import { ToastSuccess, ToastError } from "../../views/common/Toast";

const initialPasswordState = { isLoading: false, isError: "", updatePassData: "", redirect: false};

const resetPasswordSlice = createSlice({
    name: "ResetPassword",
    initialState: initialPasswordState,
    reducers: {
        apiCallRequested(state) {
            state.isLoading = true;
            state.isError = "";
            state.redirect=false
        },
        apiCallSuccess(state, action) {
            state.isLoading = false;
            state.updatePassData = action.payload;
            state.redirect=true
        },
        apiCallFailed(state, action) {
            state.isLoading = false;
            state.isError = action.payload;
            state.redirect=false;
        },
        resetState(state){
            state.isLoading = false;
            state.isError = "";
            state.redirect=false
        }
    }
});

export const resetPasswordApi = (data) => {
    return async (dispatch, getState) => {
        dispatch(resetPasswordActions.apiCallRequested());
        let formData = {
            command: "reset-password",
            ...data
        };
        try {
            const res = await axios({
                method: "POST",
                data: formData
            });
            const { errors, message } = res?.data;
            if (!errors) {
                dispatch(resetPasswordActions.apiCallSuccess(""));
                ToastSuccess(message)
            } else {
                throw message;
            }
        } catch (error) {
            dispatch(resetPasswordActions.apiCallFailed(error));
            ToastError(error)
        }
    }
};

export const resetPasswordActions = resetPasswordSlice.actions;

export default resetPasswordSlice.reducer;