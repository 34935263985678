import { useSelector } from 'react-redux';
import { PropagateLoader } from "react-spinners";
import React, { Fragment, lazy, Suspense } from "react";
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import PrivateRoute from "./PrivateRoute";

const Login = lazy(() => import("../views/auth/login"));
const GooglePopup = lazy(() => import("../views/auth/googlePopup"));
const Register = lazy(()=>import( "../views/auth/register"));
const NotFound = lazy(()=>import( "../views/pages/notFound"));
const VerifyEmail = lazy(()=>import('../views/auth/verifyEmail'));
const ResetPassword = lazy(()=>import("../views/auth/resetPassword"));
const ForgetPassword = lazy(()=>import("../views/auth/forgetPassword"));

const Home = lazy(()=>import("../views/home"));
const Dashboard = lazy(()=>import("../views/dashboard"));
const Profile = lazy(()=>import("../views/profile/profile"));
const Setings = lazy(()=>import("../views/profile/Settings"));
const Billing = lazy(()=>import("../views/profile/billing"));
const Terms = lazy(()=>import("../views/pages/static-pages"));
const Documentation = lazy(()=>import("../views/pages/docs"));
const Pricing = lazy(()=>import("../views/pages/pricing"));
const ContactUs = lazy(()=>import("../views/pages/contact"));
const CommunityFeed = lazy(()=>import("../views/pages/community-feed"));
const SearchPosts = lazy(()=>import("../views/pages/search"));
const MyFeeds = lazy(()=>import("../views/profile/feeds"));
const Support = lazy(()=>import("../views/pages/support-new"));
const ImageGeneration = lazy(()=>import("../views/pages/image-gen"));
const PromptGeneration = lazy(()=>import("../views/pages/prompt-generation"));
const ImageEditor = lazy(()=>import("../views/editor/index"));
const FeedDetail = lazy(()=>import("../views/pages/details"));
const ResizeImage = lazy(()=>import("../views/services/resizeImage"));

const Models = lazy(()=>import("../views/models"));
const Collections = lazy(()=>import("../views/collections"));
const ModelsCrud = lazy(()=>import("../views/models/models-crud"));
const GPUsCrud = lazy(()=>import("../views/gpus"));
const TokensCrud = lazy(()=>import("../views/tokens"));
const Explore = lazy(()=>import("../views/collections/explore"));

const AppRoutes = () => {
	const isLogin = useSelector(state => state.LoginRes.authResToken);
  const SuspenseLoading = () => {
      return (
        <Fragment>
          <div className="d-flex align-items-center flex-column vh-100 justify-content-center text-center py-3">
            <div className="d-flex align-items-center flex-column px-4">
              <PropagateLoader color={"#5383ff"} loading={true} />
            </div>
          </div>
        </Fragment>
      );
    };

    return (
        <Suspense fallback={<SuspenseLoading />}>
          <Router>
            <Routes>
              <Route exact path="/" className="vaikerai" element={<Dashboard />} />
              <Route exact path="/login" element={<Login />} />
              <Route exact path="/gpopup" element={<GooglePopup />} />
              <Route exact path="/register" element={<Register />} />
              <Route exact path="/forget-password" element={<ForgetPassword />} />
              <Route exact path="/reset-password/:id" element={<ResetPassword />} />
              <Route exact path="/verify-email/:id" element={<VerifyEmail />} />
              <Route exact path="/privacy-policy" element={<Terms />} />
              <Route exact path="/terms-and-conditions" element={<Terms />} />
              <Route exact path="/contact" element={<ContactUs />} />
              <Route exact path="/community-feed" element={<CommunityFeed />} />
              <Route exact path="/pricing" element={<Pricing />} />
              <Route exact path="/documentation" element={<Documentation />} />
              <Route exact path='/editor' element={<ImageEditor />}/>
              <Route exact path='/editor/:file' element={<ImageEditor />}/>
              <Route exact path='/details/:slug' element={<FeedDetail />}/>
              <Route exact path='/search/:term' element={<SearchPosts />}/>
              <Route exact path='/image-resize' element={<ResizeImage />}/>

              <Route exact path='/home' element={<PrivateRoute />}>
                <Route exact path='/home' element={<Dashboard />}/>
              </Route>

              <Route exact path='/profile' element={<PrivateRoute />}>
                <Route exact path='/profile' element={<Profile />}/>
              </Route>

              <Route exact path='/settings' element={<PrivateRoute />}>
                <Route exact path='/settings' element={<Setings />}/>
              </Route>

              <Route exact path='/my-feeds' element={<PrivateRoute />}>
                <Route exact path='/my-feeds' element={<MyFeeds />}/>
              </Route>

              <Route exact path='/billing' element={<PrivateRoute />}>
                <Route exact path='/billing' element={<Billing />}/>
              </Route>

              <Route exact path='/support' element={<PrivateRoute />}>
                <Route exact path='/support' element={<Support />}/>
              </Route>
              <Route exact path='/image-generation' element={<PrivateRoute />}>
                <Route exact path='/image-generation' element={<ImageGeneration />}/>
              </Route>
              <Route exact path='/prompt-generation' element={<PrivateRoute />}>
                <Route exact path='/prompt-generation' element={<PromptGeneration />}/>
              </Route>
              <Route exact path='/models/:page/:user/:model' element={<PrivateRoute />}>
                <Route exact path='/models/:page/:user/:model' element={<ModelsCrud />}/>
              </Route>
              <Route exact path='/models/:page' element={<PrivateRoute />}>
                <Route exact path='/models/:page' element={<ModelsCrud />}/>
              </Route>
              <Route exact path='/models' element={<PrivateRoute />}>
                <Route exact path='/models' element={<ModelsCrud />}/>
              </Route>

              <Route exact path='/gpus/:page/:id' element={<PrivateRoute />}>
                <Route exact path='/gpus/:page/:id' element={<GPUsCrud />}/>
              </Route>
              <Route exact path='/gpus/:page' element={<PrivateRoute />}>
                <Route exact path='/gpus/:page' element={<GPUsCrud />}/>
              </Route>
              <Route exact path='/gpus' element={<PrivateRoute />}>
                <Route exact path='/gpus' element={<GPUsCrud />}/>
              </Route>

              <Route exact path='/api-tokens/:page/:id' element={<PrivateRoute />}>
                <Route exact path='/api-tokens/:page/:id' element={<TokensCrud />}/>
              </Route>
              <Route exact path='/api-tokens/:page' element={<PrivateRoute />}>
                <Route exact path='/api-tokens/:page' element={<TokensCrud />}/>
              </Route>
              <Route exact path='/api-tokens' element={<PrivateRoute />}>
                <Route exact path='/api-tokens' element={<TokensCrud />}/>
              </Route>

              <Route exact path='/webhooks' element={<PrivateRoute />}>
                <Route exact path='/webhooks' element={<Dashboard />}/>
              </Route>

              <Route exact path='/trainings' element={<PrivateRoute />}>
                <Route exact path='/trainings' element={<Dashboard />}/>
              </Route>

              <Route exact path='/deployments' element={<PrivateRoute />}>
                <Route exact path='/deployments' element={<Dashboard />}/>
              </Route>

              <Route exact path='/predictions/:predictionId' element={<PrivateRoute />}>
                <Route exact path='/predictions/:predictionId' element={<Dashboard />}/>
              </Route>
              
              <Route exact path='/predictions' element={<PrivateRoute />}>
                <Route exact path='/predictions' element={<Dashboard />}/>
              </Route>

              <Route path="/404" element={<NotFound />} />
              <Route path="/not-found" element={<NotFound />} />

              <Route exact path='/collection/:slug' element={<Collections />}/>
              <Route exact path='/explore' element={<Explore />}/>
              <Route exact path='/:user/:model/:page' element={<Models />}/>
              <Route exact path='/:user/:model' element={<Models />}/>
              <Route exact path='/:user' element={<Collections />}/>

              <Route path="*" element={<NotFound />} />
            </Routes>
          </Router>
      </Suspense>
    )
};

export default AppRoutes;
