import React, { useEffect } from "react";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import { useSelector, useDispatch } from 'react-redux';

import AppRoutes from './routes/AppRoutes';
import PageLoader from "./views/common/PageLoader";
import { generalActions } from './store/general/general';

import './App.css';

const App = () =>  {
  const dispatch = useDispatch();
  const isLoading = useSelector(state => state?.LoginRes?.isLoading);
  const registerLoading =  useSelector(state=>state.RegisterData.isLoading);
  const isEditor = window.location.pathname.includes('/editor');
  
  useEffect(() => {
      dispatch(generalActions.updatePageTitle({}));
  }, []);

  return (
    <PageLoader isActive={isLoading || registerLoading}>
      <ToastContainer
        draggable
        pauseOnHover
        hideProgressBar={true}
        autoClose={5000}
        position="top-right"
        theme="colored"
      />
      <AppRoutes />
    </PageLoader>
  );
}

export default App;
