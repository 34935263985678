import { combineReducers } from "redux";
import {persistReducer} from "redux-persist";
import storage from "redux-persist/lib/storage";
import sessionStorage from 'redux-persist/lib/storage/session'

// importing reducers below
import loginReducer from "./auth/login";
import registerReducer from "./auth/register";
import profileReducer from "./auth/profile";
import updatePasswordReducer from "./auth/updatePassword";
import forgetPasswordReducer from "./auth/forgetPassword";
import resetPasswordReducer from "./auth/resetPassword";
import verifyEmailReducer from './auth/verifyEmail';
import notificationReducer from "./general/notificationForm";
import feedsReducer from "./general/feeds";
import contactReducer from "./general/contact";
import imageReducer from "./general/imageGen";
import promptReducer from "./general/promptGen";
import generalReducer from "./general/general";
import servicesReducer from "./services";

import GPUsReducer from "./gpus";
import TokensReducer from "./tokens";
import modelsReducer from "./models";
import predictionReducer from "./predictions";
import collectionsReducer from "./collections";
import webhookReducer from "./webhooks";
import trainingReducer from "./trainings";
import deploymentReducer from "./deployments";


/***
 * Add to whitelist to store in localstorage
 * ***/
const persistConfig = {
  key:"root",
  storage: storage, 
  whitelist:["LoginRes", "ProfileRes"]
};


/***
 * Add to reducers to update states in store
 * ***/
const combineReducer =  combineReducers({
  LoginRes: loginReducer,
  RegisterData: registerReducer,
  ProfileRes: profileReducer,
  updatePassData: updatePasswordReducer,
  forgetPassData: forgetPasswordReducer,
  resetPassData: resetPasswordReducer,
  verifyEmailRes: verifyEmailReducer,
  notifications: notificationReducer,
  feeds: feedsReducer,
  contact: contactReducer,
  imageGen: imageReducer,
  promptData: promptReducer,
  generalData: generalReducer,
  servicesData: servicesReducer,
  GPUsData: GPUsReducer,
  TokensData: TokensReducer,
  ModelsData: modelsReducer,
  CollectionsData: collectionsReducer,
  WebhooksData: webhookReducer,
  TrainingData: trainingReducer,
  PredictionData: predictionReducer,
  DeploymentData: deploymentReducer
});

const rootReducer = (state, action) => {
  if (action.type === 'login/logoutAPISuccess') {
    sessionStorage.removeItem("persist:root");
    state =  {};
  }else{
  }
  return combineReducer(state,action)
}


export default persistReducer(persistConfig, rootReducer)
