import { createSlice } from "@reduxjs/toolkit";
const initialData = { pageTitle: 'VaikerAI', pageDesc: 'Seamlessly run and fine-tune open-source AI models with the VaikerAI API. Deploy custom models at scale, all with a single line of code.', filesURL: "https://files.vaikerai.com", baseURL: "https://vaikerai.com", docsURL: "https://docs.vaikerai.com", contactEmail: "contact@vaikerai.com"};

const generalSlice = createSlice({
  name: "general",
  initialState: initialData,
  reducers: {
    updatePageTitle(state, action) {
      let title = (action?.payload?.title) ? action.payload.title + ' - '  + state.pageTitle : state.pageTitle;
      let description = (action?.payload?.desc) ? action.payload.desc : state.pageDesc;
      document.title = title;
      const metaDescription = document.querySelector('meta[name="description"]');
      if (metaDescription) {
        metaDescription.setAttribute('content', description);
      }
    }
  }
});

export const generalActions = generalSlice.actions;

export default generalSlice.reducer;
