import axios from "axios";
import { createSlice } from "@reduxjs/toolkit";
import { ToastError, ToastSuccess } from "../../views/common/Toast";
/***
 * Login Reducer initial state
 * ***/
const feedState = { isLoading: false, isError: null, feedsRes: null,  userFeeds: null, success: false, editorData: null, sFeedRes: null, searchFeeds: null };

const feedSlice = createSlice({
    name: "feeds",
    initialState: feedState,
    reducers: {
        apiCallRequested(state) {
            state.isLoading = true;
            state.isError = null;
            state.success = false;
        },
        apiCallFailed(state, action) {
            state.isLoading = false;
            state.isError = action.payload;
        },
        feedsSuccess(state, action){
            state.isLoading = false;
            state.feedsRes = action.payload;
            state.success=true;
        },
        sFeedSuccess(state, action){
            state.isLoading = false;
            state.sFeedRes = action.payload;
            state.success=true;
        },
        userFeedsSuccess(state, action){
            state.isLoading = false;
            state.userFeeds = action.payload;
            state.success=true;
        },
        editorDataSuccess(state, action){
            state.isLoading = false;
            state.editorData = action.payload;
            state.success=true;
        },
        searchSuccess(state, action){
            state.isLoading = false;
            state.searchFeeds = action.payload;
            state.success=true;
        },
        resetState(state){
            state.isError = null;
            state.success=false;
        }
    }
});

export const getPublicFeedsApi = () => {
    return async (dispatch) => {
        dispatch(feedActions.apiCallRequested());
        let formData = {
            command: "feeds"
        };
        try {
            const res = await axios({
                method: "POST",
                data: formData
            });

            const { errors, message, data } = res?.data;
            if(!errors){
                dispatch(feedActions.feedsSuccess(data))
            }else{
                throw message;
            }
        } catch (error) {
            dispatch(feedActions.apiCallFailed(error));
            console.log(error)
        }
    }
};

export const getUserFeedsApi = () => {
    return async (dispatch, getState) => {
        const token = getState()?.LoginRes?.authResToken;
        let formData = {
            command: "my-feeds"
        };

        dispatch(feedActions.apiCallRequested());

        try {
            const res = await axios({
                method: "POST",
                data: formData,
                headers:{
                    Authorization:`Bearer ${token}`
                }
            });

            const { errors, message, data } = res?.data;
            if(!errors){
                dispatch(feedActions.userFeedsSuccess(data));
            }else{
                throw message;
            }
        } catch (error) {
            dispatch(feedActions.apiCallFailed(error));
            console.log(error)
        }
    }
};

export const getSingleFeed = (data) => {
    return async (dispatch, getState) => {
        const token = (getState()?.LoginRes?.authResToken) ? getState()?.LoginRes?.authResToken : null;
        let formData = {
            command: "single-feed",
            ...data
        };

        dispatch(feedActions.apiCallRequested());

        try {
            let headers = {};
            if(token){
                headers = { Authorization:`Bearer ${token}` };
            }
            const res = await axios({
                method: "POST",
                data: formData,
                headers: headers
            });

            const { errors, message, data } = res?.data;
            if(!errors){
                dispatch(feedActions.sFeedSuccess(data));
            }else{
                throw message;
            }
        } catch (error) {
            dispatch(feedActions.apiCallFailed(error));
        }
    }
};


export const getSearchPosts = (data) => {
    return async (dispatch, getState) => {
        const token = (getState()?.LoginRes?.authResToken) ? getState()?.LoginRes?.authResToken : null;
        let formData = {
            command: "search",
            ...data
        };

        dispatch(feedActions.apiCallRequested());

        try {
            let headers = {};
            if(token){
                //headers = { Authorization:`Bearer ${token}` };
            }
            const res = await axios({
                method: "POST",
                data: formData,
                headers: headers
            });

            const { errors, message, data } = res?.data;
            if(!errors){
                dispatch(feedActions.searchSuccess(data));
            }else{
                throw message;
            }
        } catch (error) {
            dispatch(feedActions.apiCallFailed(error));
        }
    }
};


export const getEditorData = () => {
    return async (dispatch, getState) => {
        const token = getState()?.LoginRes?.authResToken;
        let formData = {
            command: "editor-data"
        };

        dispatch(feedActions.apiCallRequested());

        try {
            const res = await axios({
                method: "POST",
                data: formData,
                headers:{
                    Authorization:`Bearer ${token}`
                }
            });

            const { errors, message, data } = res?.data;
            if(!errors){
                dispatch(feedActions.editorDataSuccess(data));
            }else{
                throw message;
            }
        } catch (error) {
            dispatch(feedActions.apiCallFailed(error));
            console.log(error)
        }
    }
};

export const removeTemplate = (data) => {
    return async (dispatch, getState) => {
        const token = getState()?.LoginRes?.authResToken;
        let formData = {
            command: "remove-template",
            ...data
        };

        dispatch(feedActions.apiCallRequested());

        try {
            const res = await axios({
                method: "POST",
                data: formData,
                headers:{
                    Authorization:`Bearer ${token}`
                }
            });

            const { errors, message, data } = res?.data;
            if(!errors){
                ToastSuccess("Template removed successfully");
                dispatch(getEditorData());
            }else{
                throw message;
            }
        } catch (error) {
            ToastSuccess(error);
            //console.log(error);
            dispatch(feedActions.resetState());
        }
    }
};

export const removeImage = (data) => {
    return async (dispatch, getState) => {
        const token = getState()?.LoginRes?.authResToken;
        let formData = {
            command: "remove-image",
            ...data
        };

        dispatch(feedActions.apiCallRequested());

        try {
            const res = await axios({
                method: "POST",
                data: formData,
                headers:{
                    Authorization:`Bearer ${token}`
                }
            });

            const { errors, message, data } = res?.data;
            if(!errors){
                ToastSuccess("Image removed successfully");
                dispatch(getEditorData());
            }else{
                throw message;
            }
        } catch (error) {
            ToastSuccess(error);
            //console.log(error);
            dispatch(feedActions.resetState());
        }
    }
};


export const feedActions = feedSlice.actions;

export default feedSlice.reducer;
