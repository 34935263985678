import axios from "axios";
import { createSlice } from "@reduxjs/toolkit";
import { ToastSuccess, ToastError } from "../../views/common/Toast";


const initialPasswordState = { isLoading: false, isError: "", status: "", sentMail: false};

const forgotPasswordSlice = createSlice({
    name: "FogetPassword",
    initialState: initialPasswordState,
    reducers: {
        apiCallRequested(state) {
            state.isLoading = true;
            state.isError = "";
            state.sentMail= false;
        },
        apiCallSuccess(state, action) {
            state.isLoading = false;
            state.status = action.payload;
            state.sentMail= true;
        },
        apiCallFailed(state, action) {
            state.isLoading = false;
            state.isError = action.payload;
        },
        stateReset(state){
            state.isLoading = false;
            state.isError = "";
            state.sentMail= false;
        }
    }
});


export const forgotPasswordApi = (data) => {
    return async (dispatch) => {
        dispatch(forgotPasswordActions.apiCallRequested());
        let formData = {
            command: "forget-password",
            ...data
        };
        try {
            const res = await axios({
                method: "POST",
                data: formData,
            });
            const { errors, message } = res?.data;
            if (!errors) {
                dispatch(forgotPasswordActions.apiCallSuccess(''))
                ToastSuccess(message)
            } else {
                throw message
            }
        } catch (error) {
            dispatch(forgotPasswordActions.apiCallFailed(error))
            ToastError(error)
        }
    }
};


export const forgotPasswordActions = forgotPasswordSlice.actions;

export default forgotPasswordSlice.reducer;