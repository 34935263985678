import $ from 'jquery';
import React, { useEffect } from "react";
import LoadingOverlay from 'react-loading-overlay-ts';
import { BeatLoader, PropagateLoader } from "react-spinners";

const PageLoader = ({ isActive, children, individual = false }) => {

  useEffect(() => {
		if(isActive){
      $('body').addClass('overflow-hide');
		}else{
			$('body').removeClass('overflow-hide');
		}

    if(window.location.pathname.includes('/editor')){
      $('body').removeClass('vaikerai');
    }else{
			$('body').addClass('vaikerai');
		}
  }, [isActive]);

  LoadingOverlay.propTypes = undefined;

  return (
    <LoadingOverlay
      active={isActive}
      spinner={
        individual ? (
          <BeatLoader color={"#FFFFFF"} />
        ) : (
          <PropagateLoader color={"#5383ff"} />
        )
      }
      styles={{
        overlay: (base) => {
          let style = { ...base, zIndex: 1038 ,position:'fixed' };
          if (!individual) {
            style.minHeight = "100vh";
          }
          return style;
        },
      }}
    >
      {children}
    </LoadingOverlay>
  );
};

export default PageLoader;
