import axios from "axios";
import { createSlice } from "@reduxjs/toolkit";
import { ToastError, ToastSuccess } from "../../views/common/Toast";
/***
 * Login Reducer initial state
 * ***/
const ServicesState = { isLoading: false, isSuccess: false, isError: null, resizeRes: null, designPosts: null, createDPost: null, designPost: null };

const ServicesSlice = createSlice({
    name: "Services",
    initialState: ServicesState,
    reducers: {
        apiCallRequested(state) {
            state.isLoading = true;
            state.isError = null;
            state.isSuccess = false;
        },
        apiFailed(state, action) {
            state.isLoading = false;
            state.isError = action.payload;
        },
        resetState(state){
            state.isError = null;
            state.isSuccess = false;
            state.isLoading = false;
        },
        imgResizeRes(state,action){
            state.isLoading = false;
            state.resizeRes = action.payload;
            state.isSuccess = true;
        },
        getAllDPosts(state,action){
            state.isLoading = false;
            state.designPosts = action.payload;
            state.isSuccess = true;
        },
        getSingleDPost(state,action){
            state.isLoading = false;
            state.designPost = action.payload;
            state.isSuccess = true;
        },
        postDPost(state,action){
            state.isLoading = false;
            state.createDPost = action.payload;
            state.isSuccess = true;
        },
    }
});

export const imageResizeApi = (data) => {
    return async (dispatch) => {
        dispatch(servicesActions.apiCallRequested());
        let formData = {
            command: "resize-img",
            ...data
        };
        try {
            const res = await axios({
                method: "POST",
                data: formData
            });

            const { errors, message, data } = res?.data;
            if(errors === false){
                //ToastSuccess(message)
                dispatch(servicesActions.imgResizeRes(data))
            }else{
                throw message;
            }
        } catch (error) {
            dispatch(servicesActions.apiFailed(error));
            ToastError(error);
        }
    }
};

export const postAIDesign = (data) => {
    return async (dispatch, getState) => {
        const token = getState()?.LoginRes?.authResToken;
        dispatch(servicesActions.apiCallRequested());
        let formData = {
            command: "add-floor-plan",
            ...data
        };
        try {
            const res = await axios({
                method: "POST",
                data: formData,
                headers:{
                    Authorization:`Bearer ${token}`
                }
            });

            const { errors, message, data } = res?.data;
            if(errors === false){
                //ToastSuccess(message)
                dispatch(servicesActions.postDPost(data))
            }else{
                throw message;
            }
        } catch (error) {
            dispatch(servicesActions.apiFailed(error));
            ToastError(error);
        }
    }
};

export const getAIDesigns = () => {
    return async (dispatch, getState) => {
        const token = getState()?.LoginRes?.authResToken;
        dispatch(servicesActions.apiCallRequested());
        let formData = {
            command: "floor-plans"
        };
        try {
            const res = await axios({
                method: "POST",
                data: formData,
                headers:{
                    Authorization:`Bearer ${token}`
                }
            });

            const { errors, message, data } = res?.data;
            if(errors === false){
                //ToastSuccess(message)
                dispatch(servicesActions.getAllDPosts(data))
            }else{
                throw message;
            }
        } catch (error) {
            dispatch(servicesActions.apiFailed(error));
            ToastError(error);
        }
    }
};

export const getAIDesign = () => {
    return async (dispatch, getState) => {
        const token = getState()?.LoginRes?.authResToken;
        dispatch(servicesActions.apiCallRequested());
        let formData = {
            command: "floor-plan"
        };
        try {
            const res = await axios({
                method: "POST",
                data: formData,
                headers:{
                    Authorization:`Bearer ${token}`
                }
            });

            const { errors, message, data } = res?.data;
            if(errors === false){
                //ToastSuccess(message)
                dispatch(servicesActions.getSingleDPost(data))
            }else{
                throw message;
            }
        } catch (error) {
            dispatch(servicesActions.apiFailed(error));
            ToastError(error);
        }
    }
};


export const servicesActions = ServicesSlice.actions;

export default ServicesSlice.reducer;
