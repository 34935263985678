import axios from "axios";
import { createSlice } from "@reduxjs/toolkit";
import { ToastError } from "../../views/common/Toast";

const CollectionState = { isLoading: false, isSuccess: false, isError: null, apiRes: [] };

const CollectionSlice = createSlice({
    name: "Collection",
    initialState: CollectionState,
    reducers: {
        apiCall(state) {
            state.isLoading = true;
            state.isError = null;
            state.isSuccess = false;
        },
        apiFailed(state, action) {
            state.isLoading = false;
            state.isError = action.payload;
        },
        resetState(state){
            state.isError = null;
            state.isSuccess = false;
            state.isLoading = false;
            state.apiRes = [];
        },
        updateApiRes(state,action){
            state.isLoading = false;
            state.apiRes = action.payload;
            state.isSuccess = true;
        }
    }
});

export const CollectionCallApi = (data, command) => {
    return async (dispatch, getState) => {
        let apiRes = getState()?.CollectionsData?.apiRes;
        dispatch(CollectionActions.apiCall());
        let formData = {
            command: command
        };
        if(data){
            formData = {
                command: command,
                ...data
            };
        }
        try {
            const res = await axios({
                method: "POST",
                data: formData
            });

            const { errors, message, data } = res?.data;
            if(errors === false){
                apiRes = { ...apiRes, [command]: data };
                dispatch(CollectionActions.updateApiRes(apiRes));
            }else{
                throw message;
            }
        } catch (error) {
            dispatch(CollectionActions.apiFailed(error));
            ToastError(error);
        }
    }
};

export const CollectionActions = CollectionSlice.actions;

export default CollectionSlice.reducer;
