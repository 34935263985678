import React, { useEffect } from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { profileGetAPI } from "../store/auth/profile";

const PrivateRoute = ({ component: Component, user, ...rest }) => {
    const dispatch = useDispatch();
    const mloc = useLocation();
    const token = useSelector(state => state.LoginRes.authResToken);
    const profileRes = useSelector(state => state?.ProfileRes?.profileRes);

    useEffect(() => {
        if(profileRes === null){
        	dispatch(profileGetAPI(profileRes));
		}
    }, [profileRes, dispatch]);

    if (token) {
        return <Outlet />
    } else {
        return <Navigate to={ { pathname: '/login', state: { from: mloc } }  } />
    }
}

export default PrivateRoute;