import axios from "axios";
import { createSlice } from "@reduxjs/toolkit";
import { ToastSuccess } from "../../views/common/Toast";

const initialPasswordState = { isLoading: false, isError: "", updatePassData: "" };

const updatePasswordSlice = createSlice({
    name: "updatePassword",
    initialState: initialPasswordState,
    reducers: {
        apiCallRequested(state) {
            state.isLoading = true;
            state.isError = "";
        },
        apiCallSuccess(state, action) {
            state.isLoading = false;
            state.updatePassData = action.payload;
        },
        apiCallFailed(state, action) {
            state.isLoading = false;
            state.isError = action.payload;
        },
    }
});

export const updatePasswordAPi = (data) => {
    return async (dispatch,getState) => {
        const token = getState()?.LoginRes?.authResToken;
        dispatch(updatePasswordActions.apiCallRequested());
        let formData = {
            command: "password-update",
            ...data
        };
        try {
            const res = await axios({
                method: "POST", 
                //url: `/tenant/change-password`, 
                data: formData, 
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            const { error, message } = res?.data;
            if (!error) {
                const { message } = res?.data?.data;
                dispatch(updatePasswordActions.apiCallSuccess(message));
                ToastSuccess(message)
            } else {
                throw message;
            }
        } catch (error) {
            dispatch(updatePasswordActions.apiCallFailed(error));
            console.log(error)
        }
    }
};

export const updatePasswordActions = updatePasswordSlice.actions;

export default updatePasswordSlice.reducer;