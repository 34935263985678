import axios from "axios";
import { createSlice } from "@reduxjs/toolkit";
import { ToastError } from "../../views/common/Toast";
import { profileGetAPI, profileActions } from "./profile";
/***
 * Login Reducer initial state
 * ***/
const initialAuthState = { isAuthenticated: false, isLoading: false, isError: "", authResToken: null, gSessionData: null };

const authSlice = createSlice({
  name: "login",
  initialState: initialAuthState,
  reducers: {
    /***
     * Login API Reducers
     * ***/
    loginAPIRequested(state) {
      state.isLoading = true;
      state.isAuthenticated = false;
      state.isError = "";
    },
    loginAPISuccess(state, action) {
      state.isLoading = false;
      state.isError = "";
      state.isAuthenticated = true;
      state.authResToken = action.payload;
    },
    loginAPIFailure(state, action) {
      state.isLoading = false;
      state.isAuthenticated = false;
      state.isError = action.payload;
    },

    /***
     * Logout API Reducers
     * ***/

     logoutAPIRequested(state) {
      state.isLoading = true;
      state.isError = "";
    },
    logoutAPISuccess(state, action) {
      state.isLoading = false;
      state.isAuthenticated = false;
      state.authResToken = null;
    },
    logoutAPIFailure(state, action) {
      state.isLoading = false;
      state.isError = action.payload;
    },
    resetLoading(state) {
      state.isLoading = false;
    },
    gLoginSuccess(state, action) {
      state.isLoading = false;
      state.gSessionData = action.payload;
    },
    gLoginFailure(state) {
      state.isLoading = false;
      state.gSessionData = null;
    },

  },
});

/***
 * Login API
 * ***/
export const loginApi = (data) => {
  return async (dispatch) => {
    dispatch(authActions.loginAPIRequested());
    let formData = {
      command: "login",
      ...data
    };
    try {
      const res = await axios({ 
        method: "POST", 
        //url: `/tenant/login`, 
        data: formData
      });
      const { errors, message, data } = res?.data;
      if (!errors) {
        //console.log("data", data);
        const { session } = data?.info;
        //console.log("session", session);
        dispatch(authActions.loginAPISuccess(session));
        dispatch(profileActions.resetLoading());
        dispatch(profileGetAPI());
      } else {
        throw message;
      }
    } catch (error) {
      dispatch(authActions.loginAPIFailure(error));
      if(error && typeof error !== 'object'){
        ToastError(error)
        console.log(typeof error);
      }
      
    }
  }
};

export const gLoginApi = (data) => {
  return async (dispatch) => {
    dispatch(authActions.loginAPIRequested());
    let formData = {
      command: "glogin",
      ...data
    };
    try {
      const res = await axios({ 
        method: "POST",
        data: formData
      });
      const { errors, message, data } = res?.data;
      if (!errors) {
        console.log("data", data);
        const { session } = data?.info;
        //console.log("session", session);
        dispatch(authActions.loginAPISuccess(session));
        dispatch(profileActions.resetLoading());
        dispatch(profileGetAPI());
        dispatch(authActions.gLoginFailure());
      } else {
        throw message;
      }
    } catch (error) {
      dispatch(authActions.loginAPIFailure(error));
      if(error && typeof error!='object'){
        ToastError(error)
      }
      
    }
  }
};

/***
 * Logout API
 * ***/
 export const logoutApi = () => {
  return async (dispatch,getState) => {
    const token = getState()?.LoginRes?.authResToken;
    dispatch(authActions.logoutAPIRequested());
    let formData = {
      command: "logout"
    };
    try {
      const res = await axios({ 
        method: "POST", 
        //url: `/tenant/logout`,
        data: formData,
        headers: {
          Authorization: `Bearer ${token}`
        }
    });
    const { errors, message } = res?.data;
      if (!errors) {
        dispatch(authActions.logoutAPISuccess());
      } else {
        throw message;
      }
    } catch (error) {
      //dispatch login failure
      if(error === 'Session Expired, Please login again') {
        dispatch(authActions.logoutAPISuccess());
      }else{
        dispatch(authActions.logoutAPIFailure(error));
      }
      console.log('error', error)
    }
  }
};

export const authActions = authSlice.actions;
export default authSlice.reducer;
