import axios from "axios";
import { createSlice } from "@reduxjs/toolkit";
import { ToastError } from "../../views/common/Toast";

const PredictionState = { isLoading: false, isSuccess: false, isError: null, apiRes: [] };

const PredictionSlice = createSlice({
    name: "Predictions",
    initialState: PredictionState,
    reducers: {
        apiCall(state) {
            state.isLoading = true;
            state.isError = null;
            state.isSuccess = false;
        },
        apiFailed(state, action) {
            state.isLoading = false;
            state.isError = action.payload;
        },
        resetState(state){
            state.isError = null;
            state.isSuccess = false;
            state.isLoading = false;
            state.apiRes = [];
        },
        updateApiRes(state,action){
            state.isLoading = false;
            state.apiRes = action.payload;
            state.isSuccess = true;
        }
    }
});

export const PredictionCallApi = (data, command) => {
    return async (dispatch, getState) => {
        let apiRes = getState()?.PredictionData?.apiRes;
        const token = getState()?.LoginRes?.authResToken;
        dispatch(PredictionActions.apiCall());
        let formData = {
            command: command
        };
        if(data){
            formData = {
                command: command,
                ...data
            };
        }
        let headers = {};
        if(token){
            headers = {
                Authorization:`Bearer ${token}`
            }
        }
        try {
            const res = await axios({
                method: "POST",
                data: formData,
                headers: headers
            });

            const { errors, message, data } = res?.data;
            if(errors === false){
                apiRes = { ...apiRes, [command]: data };
                apiRes[command] = data;
                dispatch(PredictionActions.updateApiRes(apiRes));
            }else{
                throw message;
            }
        } catch (error) {
            dispatch(PredictionActions.apiFailed(error));
            ToastError(error);
        }
    }
};

export const PredictionActions = PredictionSlice.actions;

export default PredictionSlice.reducer;
