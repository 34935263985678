import { configureStore } from '@reduxjs/toolkit';
import { persistStore } from "redux-persist"
import reducer from "./reducers";


export const store = configureStore({ 
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({serializableCheck:false}),
    reducer,
});

export const persistor = persistStore(store)