import axios from "axios";
import { createSlice } from "@reduxjs/toolkit";
import { ToastError, ToastSuccess } from "../../views/common/Toast";
/***
 * Login Reducer initial state
 * ***/
const PromptGenState = { isLoading: false, isError: null, promptGenRes: null, isSuccess: false, isPSuccess: false, myPrompts: null };

const PromptGenSlice = createSlice({
    name: "PromptGen",
    initialState: PromptGenState,
    reducers: {
        apiCallRequested(state) {
            state.isLoading = true;
            state.isError = null;
            state.isSuccess = false;
            state.isPSuccess = false;
        },
        apiFailed(state, action) {
            state.isLoading = false;
            state.isError = action.payload;
        },
        apiSuccess(state,action){
            state.isLoading = false;
            state.feedsRes = action.payload;
            state.isSuccess = true;
        },
        apiPromptsSuccess(state,action){
            state.isLoading = false;
            state.myPrompts = action.payload;
            state.isPSuccess = true;
        },
        resetState(state){
            state.isError = null;
            state.isSuccess = false;
            state.isLoading = false;
            state.isPSuccess = false;
        }
    }
});

export const postPromptGenApi = (data) => {
    return async (dispatch, getState) => {
        dispatch(PromptGenActions.apiCallRequested());
        const token = getState()?.LoginRes?.authResToken;
        let formData = {
            command: "generate-prompts",
            ...data
        };
        try {
            const res = await axios({
                method: "POST",
                data: formData,
                headers:{
                    Authorization:`Bearer ${token}`
                }
            });

            const { errors, message, data } = res?.data;
            if(errors === false){
                ToastSuccess(message)
                dispatch(PromptGenActions.apiSuccess(data))
                dispatch(getPromptsApi())
            }else{
                throw message;
            }
        } catch (error) {
            dispatch(PromptGenActions.apiFailed(error));
            ToastError(error);
        }
    }
};

export const getPromptsApi = () => {
    return async (dispatch, getState) => {
        dispatch(PromptGenActions.apiCallRequested());
        const token = getState()?.LoginRes?.authResToken;

        let formData = {
            command: "my-prompts"
        };

        try {
            const res = await axios({
                method: "POST",
                data: formData,
                headers:{
                    Authorization:`Bearer ${token}`
                }
            });

            const { errors, message, data } = res?.data;
            if(!errors){
                dispatch(PromptGenActions.apiPromptsSuccess(data));
            }else{
                throw message;
            }
        } catch (error) {
            dispatch(PromptGenActions.apiFailed(error));
            console.log(error)
        }
    }
};


export const PromptGenActions = PromptGenSlice.actions;

export default PromptGenSlice.reducer;
