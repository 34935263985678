import axios from "axios";
import { createSlice } from "@reduxjs/toolkit";
import { ToastError, ToastSuccess } from "../../views/common/Toast";
/***
 * Login Reducer initial state
 * ***/
const ContactState = { isLoading: false, isError: null, isSuccess: false, contactRes: null, supportRes: null, replyRes: null, ticketsRes: null };

const ContactSlice = createSlice({
    name: "Contact",
    initialState: ContactState,
    reducers: {
        apiCallRequested(state) {
            state.isLoading = true;
            state.isError = null;
            state.isSuccess = false;
        },
        apiFailed(state, action) {
            state.isLoading = false;
            state.isError = action.payload;
        },
        resetState(state){
            state.isError = null;
            state.isSuccess = false;
            state.contactRes = null;
            state.supportRes = null;
            state.replyRes = null;
        },
        apiSuccess(state,action){
            state.isLoading = false;
            state.contactRes = action.payload;
            state.isSuccess = true;
        },
        apiSupportSuccess(state,action){
            state.isLoading = false;
            state.supportRes = action.payload;
            state.isSuccess = true;
        },
        apiReplySuccess(state,action){
            state.isLoading = false;
            state.replyRes = action.payload;
            state.isSuccess = true;
        },
        apiTicketsSuccess(state,action){
            state.isLoading = false;
            state.ticketsRes = action.payload;
            state.isSuccess = true;
        }
    }
});

export const postContactApi = (data) => {
    return async (dispatch) => {
        dispatch(ContactActions.apiCallRequested());
        let formData = {
            command: "contact",
            ...data
        };
        try {
            const res = await axios({
                method: "POST",
                data: formData
            });

            const { errors, message, data } = res?.data;
            if(!errors){
                ToastSuccess(message)
                dispatch(ContactActions.apiSuccess(data))
            }else{
                throw message;
            }
        } catch (error) {
            dispatch(ContactActions.apiFailed(error));
            ToastError(error);
        }
    }
};

export const postSupportApi = (data) => {
    return async (dispatch, getState) => {
        const token = getState()?.LoginRes?.authResToken;
        dispatch(ContactActions.apiCallRequested());
        let formData = {
            command: "support",
            ...data
        };
        try {
            const res = await axios({
                method: "POST",
                data: formData,
                headers:{
                    Authorization:`Bearer ${token}`
                }
            });

            const { errors, message, data } = res?.data;
            if(!errors){
                ToastSuccess(message)
                dispatch(ContactActions.apiSupportSuccess(data))
                dispatch(getTicketsApi())
            }else{
                throw message;
            }
        } catch (error) {
            dispatch(ContactActions.apiFailed(error));
            ToastError(error);
        }
    }
};

export const postReplyApi = (data) => {
    return async (dispatch, getState) => {
        const token = getState()?.LoginRes?.authResToken;
        dispatch(ContactActions.apiCallRequested());
        let formData = {
            command: "support-reply",
            ...data
        };
        try {
            const res = await axios({
                method: "POST",
                data: formData,
                headers:{
                    Authorization:`Bearer ${token}`
                }
            });

            const { errors, message, data } = res?.data;
            if(!errors){
                ToastSuccess(message)
                dispatch(ContactActions.apiReplySuccess(data))
                dispatch(getTicketsApi())
            }else{
                throw message;
            }
        } catch (error) {
            dispatch(ContactActions.apiFailed(error));
            ToastError(error);
        }
    }
};

export const getTicketsApi = (data) => {
    return async (dispatch, getState) => {
        const token = getState()?.LoginRes?.authResToken;
        dispatch(ContactActions.apiCallRequested());
        let formData = {
            command: "support-tickets",
            ...data
        };
        try {
            const res = await axios({
                method: "POST",
                data: formData,
                headers:{
                    Authorization:`Bearer ${token}`
                }
            });

            const { errors, message, data } = res?.data;
            if(!errors){
                dispatch(ContactActions.apiTicketsSuccess(data))
            }else{
                throw message;
            }
        } catch (error) {
            dispatch(ContactActions.apiFailed(error));
            ToastError(error);
        }
    }
};


export const ContactActions = ContactSlice.actions;

export default ContactSlice.reducer;
