import axios from "axios";
import { createSlice } from "@reduxjs/toolkit";
import { ToastSuccess, ToastError } from "../../views/common/Toast";

const initialPasswordState = { isLoading: false, isError: "", verifyEmailRes: "" , redirect: false};

const verifyEmailSlice = createSlice({
    name: "verifyEmail",
    initialState: initialPasswordState,
    reducers: {
        apiCallRequested(state) {
            state.isLoading = true;
            state.isError = "";
        },
        apiCallSuccess(state, action) {
            state.isLoading = false;
            state.verifyEmailRes = action.payload;
        },
        apiCallFailed(state, action) {
            state.isLoading = false;
            state.isError = action.payload;
        },
        resetState(state){
            state.isLoading = false;
            state.isError = "";
        }
    }
});


export const verifyEmailApi = (data) => {
    return async (dispatch, getState) => {
        dispatch(verifyEmailActions.apiCallRequested());
        let formData = {
            command: "verify-email",
            ...data
        };
        try {
            const res = await axios({
                method: "POST", 
                data: formData
            });
            const { errors, message } = res?.data;
            if (!errors) {
                dispatch(verifyEmailActions.apiCallSuccess(message));
                ToastSuccess(message)
            } else {
                throw message;
            }
        } catch (error) {
            dispatch(verifyEmailActions.apiCallFailed(error));
            ToastError(error)
        }
    }
};

export const verifyEmailActions = verifyEmailSlice.actions;

export default verifyEmailSlice.reducer;