import axios from "axios";
import { createSlice } from "@reduxjs/toolkit";
import { ToastSuccess, ToastError } from "../../views/common/Toast";

/***
 * Register Reducer initial state
 * ***/
const initialAuthState = { registerRes: null, isLoading: false, isError: "", isSubmitted: false };

const regsiterSlice = createSlice({
  name: "register",
  initialState: initialAuthState,
  reducers: {
    registerRequested(state) {
      state.isLoading = true;
      state.isError = "";
      state.isSubmitted=false;
    },
    registerSuccess(state, action) {
      state.isLoading = false;
      state.registerRes = action.payload;
      state.isSubmitted=true
    },
    registerFailure(state, action) {
      state.isLoading = false;
      state.isError = action.payload;
      state.isSubmitted=false
    },
    resetState(state,action){
      state.isLoading = false;
      state.isError = "";
      state.isSubmitted=false;
    }
  },
});

/***
 * Register API
 * ***/
export const registerApi = (data) => {
  return async (dispatch) => {
    dispatch(registerActions.registerRequested());
    let formData = {
      command: "register",
      ...data
    };
    try {
      const res = await axios({ 
        method: "POST", 
        //url: `/tenant/register`, 
        data: formData 
      });
      const { errors, message, data } = res?.data;
      if (!errors) {
        ToastSuccess(message)
        dispatch(registerActions.registerSuccess(data))
      } else {
        throw message;
      }
    } catch (error) {
      dispatch(registerActions.registerFailure(error))
      if(typeof error === 'string')
        ToastError(error)
    }
  }
};

export const registerActions = regsiterSlice.actions;

export default regsiterSlice.reducer;
